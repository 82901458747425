<template>
  <footer>
    <new-project-modal />
  </footer>
</template>

<script>
import { defineAsyncComponent, reactive, toRefs } from 'vue'


export default {
  name: "FooterApp",
  components: {
    NewProjectModal: defineAsyncComponent(() => import('@/components/projects/create/Index') ),
  },
  setup () {
    const state = reactive({})

    const onRemove = () => {
     console.log('ad')
    }
    return {
      onRemove,
      ...toRefs(state)
    }
  }
}
</script>


<style lang="scss" scoped>
  .header {
    background: #fff;

    &-app{
      &__title {
        font-weight: bold;
      }
    }
  }

  .navbar {
    left: 80px;
  }

</style>
